var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "absolute inset-x-0 bottom-0" }, [
    _c(
      "p",
      {
        staticClass: "text-sm leading-5 text-center text-gray-600 w-4/5 m-auto",
      },
      [
        _vm._v(" " + _vm._s(_vm.$t("login.footer.firstMessage")) + " "),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.$t("login.footer.secondMessage")) + " "),
        _c("br"),
        _c(
          "a",
          {
            staticClass:
              "mt-10 font-medium transition duration-150 ease-in-out text-nexteal-500 hover:text-nexblue-500 focus:outline-none focus:underline",
            attrs: { href: _vm.$t("linkNex"), "data-cy": "login-link-a" },
          },
          [_vm._v(" " + _vm._s(_vm.$t("login.footer.thirdMessage")) + " ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }