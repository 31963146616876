<template>
    <div class="flex items-center justify-center min-h-screen bg-white">
        <div class="relative w-full max-w-md" style="min-height: 580px">
            <div class="p-2">
                <img
                    class="pb-10 mx-auto"
                    src="@/assets/images/logo.png"
                    width="90px"
                    alt="Nextar"
                    loading="lazy"
                    data-cy="login-nex-img"
                />

                <form class="w-full mt-8" method="post" @submit.prevent="login">
                    <input type="hidden" name="remember" value="true" />
                    <div class="rounded-md shadow-sm">
                        <div>
                            <input
                                id="user_email"
                                v-model="user.email"
                                maxlength="255"
                                class="nex-input"
                                required
                                type="email"
                                :placeholder="$t('email')"
                                data-cy="login-email-input"
                            />
                        </div>
                        <div class="mt-3">
                            <input
                                id="user_password"
                                v-model="user.password"
                                class="nex-input"
                                required
                                type="password"
                                :placeholder="$t('password')"
                                data-cy="login-password-input"
                            />
                        </div>
                    </div>

                    <div class="mt-6">
                        <submit-button-default
                            :text="$t('enter')"
                            data-cy="login-submit-btn"
                            :loading="loading"
                        />
                    </div>
                    <div class="text-sm leading-5 mt-10 text-center">
                        <a
                            @click="goToRecoverPassword"
                            data-cy="login-forgot-a"
                            class="font-medium cursor-pointer transition duration-150 ease-in-out text-nexblue-600 hover:text-nexblue-500 focus:outline-none focus:underline"
                        >
                            {{ $t("login.forgetPassword") }}
                        </a>
                    </div>
                </form>
                <div class="w-full flex justify-center mt-3">
                    <base-select
                        v-model="language"
                        :white-option="false"
                        uid="languages"
                        class="w-1/2 h-1"
                        label=""
                        :list="$t('languages')"
                        data-cy="login-language-select"
                        @change.native="changeLanguage"
                    />
                </div>
                <div>
                    <login-footer data-cy="login-footer-text" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import BaseSelect from "@/components/Form/BaseSelect.vue";
import LoginFooter from "@/components/Login/LoginFooter.vue";
import { mapGetters, mapActions } from "vuex";
import {
    CHANGE_LANGUAGE,
    CHECK_TOKEN,
    LOGIN,
    LOGIN_MODULE,
} from "@/store/constants/login";
import { HOME_VIEW, RECOVER_PASSWORD_VIEW } from "@/router/constants";

export default {
    name: "LoginView",
    components: {
        SubmitButtonDefault,
        BaseSelect,
        LoginFooter,
    },
    data() {
        return {
            user: {
                email: null,
                password: null,
            },
            language: null,
            loading: false,
        };
    },

    computed: {
        ...mapGetters({
            getLanguage: "language",
        }),
    },

    created() {
        this.languagekeysMapper();
    },

    methods: {
        ...mapActions({
            setLanguage: CHANGE_LANGUAGE,
            loginAction: `${LOGIN_MODULE}/${LOGIN}`,
            checkToken: `${LOGIN_MODULE}/${CHECK_TOKEN}`,
        }),
        languagekeysMapper() {
            const languageKeys = Object.keys(this.$t("languages"));
            languageKeys.map((lang) => {
                if (this.$t("languages")[lang] === this.getLanguage) {
                    this.language = lang;
                }
            });
        },
        changeLanguage() {
            const languageKeys = Object.keys(this.$t("languages"));
            languageKeys.map((lang) => {
                if (lang === this.language) {
                    const language = this.$t("languages")[lang];
                    this.setLanguage(language);
                    this.$i18n.locale = language;
                    localStorage.setItem("lang", language);
                }
            });
            this.languagekeysMapper(languageKeys);
        },
        async login() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            const credentials = this.user;

            await this.loginAction(credentials)
                .catch((error) => this.loginErrorHandling(error))
                .finally(() => (this.loading = false));

            this.$router.push({ name: HOME_VIEW });
        },

        loginErrorHandling(error) {
            let message = this.$t("login.failedLogin");
            if (error?.errors[0].message) {
            if (error?.errors[0].message === "not_confirmed") {
                message = this.$t("login.failedLoginEmail");
            }
            if (!error.message) {
                message = this.$t("errors.server");
            }
            }
            this.$fire({
                text: message,
                type: "error",
                timer: 6000,
                customClass: "alert-error-1",
            });
            throw new Error(JSON.stringify(error));
        },

        async goToRecoverPassword() {
            this.$router.push({ name: RECOVER_PASSWORD_VIEW });
        },
    },
};
</script>

<style lang="scss" scoped></style>
