<template>
  <div class="input-group" :class="{ error: error }">
    <label :for="`id${uid}`">
      {{ label }}
    </label>
    <select
      :id="`id${uid}`"
      :value="value"
      class="nex-input"
      @change="changeValue($event)"
    >
      <option v-if="whiteOption" value="" />
      <option
        v-for="(item, key) in list"
        :key="key"
        :value="key"
        :selected="value == key"
      >
        {{ key }}
      </option>
    </select>
    <small v-html="message" />
  </div>
</template>

<script>
export default {
  name: "InputGroup",
  props: {
    uid: {
      type: String,
      required: true,
      default: null,
    },
    label: {
      type: String,
      required: true,
      default: null,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    list: {
      type: Object,
      required: true,
      default: new Object(),
    },
    whiteOption: {
      type: Boolean,
      required: false,
      default: true,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    message: {
      type: String,
      required: false,
      default: null,
    },
  },

  methods: {
    changeValue(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>
