var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center justify-center min-h-screen bg-white" },
    [
      _c(
        "div",
        {
          staticClass: "relative w-full max-w-md",
          staticStyle: { "min-height": "580px" },
        },
        [
          _c("div", { staticClass: "p-2" }, [
            _c("img", {
              staticClass: "pb-10 mx-auto",
              attrs: {
                src: require("@/assets/images/logo.png"),
                width: "90px",
                alt: "Nextar",
                loading: "lazy",
                "data-cy": "login-nex-img",
              },
            }),
            _c(
              "form",
              {
                staticClass: "w-full mt-8",
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.login.apply(null, arguments)
                  },
                },
              },
              [
                _c("input", {
                  attrs: { type: "hidden", name: "remember", value: "true" },
                }),
                _c("div", { staticClass: "rounded-md shadow-sm" }, [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.email,
                          expression: "user.email",
                        },
                      ],
                      staticClass: "nex-input",
                      attrs: {
                        id: "user_email",
                        maxlength: "255",
                        required: "",
                        type: "email",
                        placeholder: _vm.$t("email"),
                        "data-cy": "login-email-input",
                      },
                      domProps: { value: _vm.user.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.user, "email", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "mt-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.password,
                          expression: "user.password",
                        },
                      ],
                      staticClass: "nex-input",
                      attrs: {
                        id: "user_password",
                        required: "",
                        type: "password",
                        placeholder: _vm.$t("password"),
                        "data-cy": "login-password-input",
                      },
                      domProps: { value: _vm.user.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.user, "password", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-6" },
                  [
                    _c("submit-button-default", {
                      attrs: {
                        text: _vm.$t("enter"),
                        "data-cy": "login-submit-btn",
                        loading: _vm.loading,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-sm leading-5 mt-10 text-center" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "font-medium cursor-pointer transition duration-150 ease-in-out text-nexblue-600 hover:text-nexblue-500 focus:outline-none focus:underline",
                        attrs: { "data-cy": "login-forgot-a" },
                        on: { click: _vm.goToRecoverPassword },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("login.forgetPassword")) + " "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "w-full flex justify-center mt-3" },
              [
                _c("base-select", {
                  staticClass: "w-1/2 h-1",
                  attrs: {
                    "white-option": false,
                    uid: "languages",
                    label: "",
                    list: _vm.$t("languages"),
                    "data-cy": "login-language-select",
                  },
                  nativeOn: {
                    change: function ($event) {
                      return _vm.changeLanguage.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.language,
                    callback: function ($$v) {
                      _vm.language = $$v
                    },
                    expression: "language",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("login-footer", {
                  attrs: { "data-cy": "login-footer-text" },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }