var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-group", class: { error: _vm.error } },
    [
      _c("label", { attrs: { for: `id${_vm.uid}` } }, [
        _vm._v(" " + _vm._s(_vm.label) + " "),
      ]),
      _c(
        "select",
        {
          staticClass: "nex-input",
          attrs: { id: `id${_vm.uid}` },
          domProps: { value: _vm.value },
          on: {
            change: function ($event) {
              return _vm.changeValue($event)
            },
          },
        },
        [
          _vm.whiteOption ? _c("option", { attrs: { value: "" } }) : _vm._e(),
          _vm._l(_vm.list, function (item, key) {
            return _c(
              "option",
              {
                key: key,
                domProps: { value: key, selected: _vm.value == key },
              },
              [_vm._v(" " + _vm._s(key) + " ")]
            )
          }),
        ],
        2
      ),
      _c("small", { domProps: { innerHTML: _vm._s(_vm.message) } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }