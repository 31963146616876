<template>
  <div class="absolute inset-x-0 bottom-0">
    <p class="text-sm leading-5 text-center text-gray-600 w-4/5 m-auto">
      {{ $t("login.footer.firstMessage") }}
      <br />
      {{ $t("login.footer.secondMessage") }}
      <br />
      <a
        :href="$t('linkNex')"
        class="
          mt-10
          font-medium
          transition
          duration-150
          ease-in-out
          text-nexteal-500
          hover:text-nexblue-500
          focus:outline-none focus:underline
        "
        data-cy="login-link-a"
      >
        {{ $t("login.footer.thirdMessage") }}
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "LoginFooter",
  data() {
    return {};
  },
};
</script>

<style lang="css" scoped></style>
